import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import { ExternalButtonLink } from "components/buttons"

// BG
import HeroBg from "assets/images/careers/hero.jpg"

const StyledHero = styled.header`
  display: flex;
  flex-wrap: wrap;
  border-bottom-width: 0;
  overflow: hidden;
  background: url(${HeroBg});
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  flex-direction: column-reverse; 
  opacity: 90%;
    
  ${breakpoint.small`
    flex-wrap: nowrap;
    flex-direction: row;
    height: 500px;
  `}

  ${breakpoint.medium`
    height: 650px;
    flex-wrap: nowrap;
  `}

  ${breakpoint.large`
    height: 900px;
  `}


  .block--text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 90px 30px 50px;
    background: #3337;

    ${breakpoint.xs`
      background: revert;
    `}

    ${breakpoint.small`
      border-top: 0;
      padding: 60px 30px;
    `}

    ${breakpoint.medium`
      width: calc(100vw - 400px);
    `}

    ${breakpoint.large`
      width: calc(100vw - 600px);
    `}

    ${breakpoint.xxl`
      width: calc(100vw - 750px);
    `}

    > div {
      width: 100%;
      align-self: flex-end;

      ${breakpoint.medium`
        max-width: 700px;
      `}
    }
    
	h1 { 
		margin-bottom: 0.2rem;
    	color: ${colors.white};
	    text-shadow: 4px 3px 5px ${colors.charcoal};
	  }

	p { 
		margin-bottom: 1rem;
		color: ${colors.white};
	    text-shadow: 4px 3px 5px ${colors.charcoal};
	}
	  }

	a {
		max-width: 200px;
	}

  }
`

const Hero = () => {
  return (
    <StyledHero data-aos="fade-in">
      <div className="block--text">
        <div>
          <h1>Share our vision</h1>
          <p>
            At Visby, everyone’s voices and ideas are valued because we know that our sum is greater when each teammate’s whole self is brought to the table. If you’re ready to usher in the future of diagnostics, check out our open roles today.
          </p>
          <ExternalButtonLink href="https://boards.greenhouse.io/visbymedical" target="_blank" rel="noopener noreferrer" backgroundcolor="primary">
            See Job Openings
          </ExternalButtonLink>
        </div>
      </div>
    </StyledHero>
  )
}
export default Hero
